import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBirthdayCake, faUser, faMagnet, faRulerVertical, faBriefcase, faBook, faBaby, faWater, faAngleDown, faFutbol, faTableTennisPaddleBall, faPersonSnowboarding, faMartiniGlassCitrus, faChessBoard, faEarthAmericas, faSchoolFlag, faMoneyBill, faPlaneDeparture, faFootball, faChampagneGlasses, faFish, faMusic, faBicycle, faHouse, faPeopleRobbery, faTrain, faHeart, faSeedling, faCity, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Paragraph from './components/Paragraph';
import Image from './components/Image';
import BulletedList from './components/BulletedList';
import IconList from './components/IconList';
import LivedList from './components/LivedList';
import Video from './components/Video';

import { app, analytics } from './firebase';

library.add(faBirthdayCake, faUser, faMagnet, faRulerVertical, faBriefcase, faBook, faBaby, faWater, faAngleDown, faFutbol, faTableTennisPaddleBall, faPersonSnowboarding, faMartiniGlassCitrus, faChessBoard, faEarthAmericas, faSchoolFlag, faMoneyBill, faPlaneDeparture, faFootball, faChampagneGlasses, faFish, faMusic, faBicycle, faHouse, faPeopleRobbery, faTrain, faHeart, faSeedling, faCity, faLocationPin);

const App = () => {
  return (
    <div className="App">
      <Header text="Hi &#128075; - I'm Lucas" />
      <Image src="/img/BoliviaYellow.jpeg" />
      <IconList
        title="The Basics"
        items={[
          { icon: faBirthdayCake, text: "31" },
          { icon: faUser, text: "Man" },
          { icon: faMagnet, text: "Straight" },
          { icon: faLocationPin, text: "NYC (Manhattan)" },
          { icon: faRulerVertical, text: `5'8"` },
          { icon: faBriefcase, text: "Product Manager" },
          { icon: faBook, text: "Christian" },
          { icon: faBaby, text: "Wants Kids" },
          { icon: faWater, text: "Aquarius" }
        ]}
      />
      <Image src="/img/Turtle.jpeg" />
      <LivedList />
      <Image src="/img/camel.jpg" />
      <IconList
        title="Things I do for Fun"
        items={[
          { icon: faFutbol, text: [
            "I play ",
            { text: "social league soccer", bold: true },
            " at least once a week"
          ] },
          { icon: faTableTennisPaddleBall, text: [
            "I've also been a frequent ",
            { text: "pickeball player", bold: true },
            " since my parents got me started playing back in 2021"
          ] },
          { icon: faPersonSnowboarding, text: [
            "In the winter you can find me ",
            { text: "snowboarding", bold: true },
            " somewhere out West like Park City or Tahoe"
          ] },
          { icon: faMartiniGlassCitrus, text: [
            "I enjoy a night out exploring the city's ",
            { text: "cocktail scene", bold: true },
            " (and creating my own!)"
          ] },
          { icon: faChessBoard, text: [
            "But I'm also always down for a night in playing ",
            { text: "board games", bold: true }
          ] },
          { icon: faEarthAmericas, text: [
            "I'm huge fan of ",
            { text: "travel", bold: true },
            " and spend nearly all my vacation days on trips abroad",
          ] }
        ]}
      />
      <Image src="/img/cc.jpg" />
      <IconList
        title="Professional Things"
        items={[
          { icon: faBriefcase, text: [
            "My current job is working as a ",
            { text: "Product Manager", bold: true },
            " at one of the big banks"
          ] },
          { icon: faSchoolFlag, text: [
            "I'm in the process of applying to grad school to get my Master's degree in either ",
            { text: "Computer Science or Artificial Intelligence", bold: true },
            " with the goal of switching to become a machine learning engineer"
          ] },
          { icon: faMoneyBill, text: [
            "One of my other passions is  ",
            { text: "Angel Investing", bold: true },
            " in early stage startups.  You can frequently find me at various tech meetups around the city networking with founders"
          ] }
        ]}
      />
      <Video src="/video/snowboarding.mp4" />
      <IconList
        title="Together we could..."
        items={[
          { icon: faChampagneGlasses, text: [
            "Check out all the best ",
            { text: "wine tastings, breweries, and mezcal flights", bold: true },
            " that the city has to offer"
          ] },
          { icon: faPlaneDeparture, text: [
            "Find great ",
            { text: "flight deals", bold: true },
            " using points to places off the beaten path"
          ] },
          ,
          { icon: faFootball, text: [
            "Spend Saturdays watching ",
            { text: "college football", bold: true },
            " #RollTide"
          ] },
          { icon: faFish, text: [
            "Watch reruns of ",
            { text: "Shark Tank", bold: true },
            " and finish our Christmas shopping by buying all of the best products"
          ] },
          { icon: faMusic, text: [
            "Dance along to ",
            { text: "live music", bold: true },
            " at concerts and festivals"
          ] },
        ]}
      />
      <Image src="/img/brunch.jpg" />
      <IconList
        title="Ask Me About"
        items={[
          { icon: faBicycle, text: [
            "Biking death road in Bolivia"
          ] },
          { icon: faHouse, text: [
            "Waking up in a seersucker blazer on a roof in Charleston with North Korean currency in my pocket"
          ] },
          ,
          { icon: faPeopleRobbery, text: [
            "Fighting off muggers in Buenos Aires "
          ] },
          { icon: faTrain, text: [
            "Commuting from NYC to DC every week over the summer to play soccer"
          ] },
        ]}
      />
      <Image src="/img/biking.jpeg" />
      <IconList
        title="Green Flags I Look For"
        items={[
          { icon: faHeart, text: [
            "Genuinely kind - you treat everyone with respect regardless of their social standing"
          ] },
          { icon: faSeedling, text: [
            "Has passions and pursuits outside of work and hanging out with friends"
          ] },
          ,
          { icon: faCity, text: [
            "Obsessed with this city and all it has to offer"
          ] }
        ]}
      />
      <Header text="Interested?" />
      <Paragraph content="Send me a like and let's go on a date" />
    </div>
  );
};

export default App;
