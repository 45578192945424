// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXKJlFC4JXz5yLtA2hBvDuBqQ1zQNddck",
  authDomain: "date-lucas.firebaseapp.com",
  projectId: "date-lucas",
  storageBucket: "date-lucas.appspot.com",
  messagingSenderId: "365601815225",
  appId: "1:365601815225:web:79302eaa02df5871787968",
  measurementId: "G-4801FMBF6N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };