import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const IconList = ({ title, items }) => (
    <div className="container-fluid p-3">
      <h3 className="mb-5 fs-2">My Story</h3>
        <div className="row">
            <div className="col-8 mx-auto">
                <img src="/img/town.jpg" className="img-fluid rounded" alt="Me" />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-4">
                <p className="text-center fw-bold">Small Town Georgia</p>
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto text-center mb-4">
                <FontAwesomeIcon 
                icon={faAngleDown} 
                className="me-3" 
                style={{ minWidth: '1.25em' }}
                size="5x"
                />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-2">
                <img src="/img/terps.jpg" className="img-fluid rounded" alt="Me" />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-4">
                <p className="text-center fw-bold">University of Maryland</p>
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto text-center mb-4">
                <FontAwesomeIcon 
                icon={faAngleDown} 
                className="me-3" 
                style={{ minWidth: '1.25em' }}
                size="5x"
                />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-3">
                <img src="/img/sf.png" className="img-fluid rounded" alt="Me" />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-4">
                <p className="text-center fw-bold">San Francisco</p>
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto text-center mb-4">
                <FontAwesomeIcon 
                icon={faAngleDown} 
                className="me-3" 
                style={{ minWidth: '1.25em' }}
                size="5x"
                />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-3">
                <img src="/img/dc.png" className="img-fluid rounded" alt="Me" />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-4">
                <p className="text-center fw-bold">DC / NOVA</p>
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto text-center mb-4">
                <FontAwesomeIcon 
                icon={faAngleDown} 
                className="me-3" 
                style={{ minWidth: '1.25em' }}
                size="5x"
                />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-2">
                <img src="/img/nyc.png" className="img-fluid rounded" alt="Me" />
            </div>
        </div>
        <div className="row">
            <div className="col-8 mx-auto mb-4">
                <p className="text-center fw-bold">NYC</p>
            </div>
        </div>
    </div>
  );
  
  export default IconList;