import React from 'react';

const Video = ({ src, autoPlay = false, controls = true, muted = false }) => (
  <div className="container-fluid p-3">
    <video 
      src={src} 
      className="w-100" 
      autoPlay={autoPlay} 
      controls={controls} 
      muted={muted}
    >
      Your browser does not support the video tag.
    </video>
  </div>
);

export default Video;